import React, { useEffect, useRef, useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { FaPlus } from 'react-icons/fa6';
import { HiOutlineTrash } from 'react-icons/hi';

import { IUser } from '@/@types/auth';
import { IWorkExperiences } from '@/@types/profile';
import Container from '@/components/Container';
import DatePickerField from '@/components/DatePickerField';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/seperator';
import { Textarea } from '@/components/ui/textarea';
import { WORK_EXPERIENCE_FIELDS } from '@/constants/profile';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useFormContext } from '@/provider/FormProvider/index';
import { workExperiencesStep } from '@/services/profile';
import { hasAnyValue } from '@/utils/common';

const WorkExperience = () => {
  const { profile: staticText, common } = strings;

  const { stepFour, handleBack, handleSkip, setData, handleNext } =
    useFormContext();
  const { user, setUser } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const hasInitialized = useRef(false);
  const [showError, setShowError] = useState(false);

  const newSectionRef = useRef<HTMLDivElement | null>(null);
  const userWorkExperiences = {
    workExperiences: user?.workExperiences || [],
  } as IWorkExperiences;

  const {
    control,
    watch,
    formState: { errors, isDirty, isSubmitted },
    handleSubmit,
  } = useForm<IWorkExperiences>({
    mode: 'all',
    defaultValues: userWorkExperiences || stepFour || { workExperiences: [] },
  });

  const { fields, append, remove } = useFieldArray<
    IWorkExperiences,
    'workExperiences',
    'id'
  >({
    control,
    name: 'workExperiences',
  });

  const addNewSection = () => {
    append({
      nameOfCompany: '',
      position: '',
      salary: 0,
      startDate: '',
      endDate: '',
      reasonForLeaving: '',
    });
    setTimeout(() => {
      if (newSectionRef.current) {
        newSectionRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 100);
  };

  const deleteSection = (index: number) => {
    remove(index);
  };

  const workExperienceMutation = useGenericMutation<
    IWorkExperiences,
    IUser | boolean
  >(workExperiencesStep, {
    onSuccess: (result) => {
      if (typeof result !== 'boolean') {
        setData({
          step: 4,
          data: result?.workExperiences as IWorkExperiences,
        });
        setUser({ ...user, workExperiences: result?.workExperiences } as IUser);
        handleNext();
      }
    },
  });

  const onSubmit: SubmitHandler<IWorkExperiences> = (data) => {
    if (isDirty) {
      workExperienceMutation.mutate(data);
    } else {
      handleNext();
    }
  };

  useEffect(() => {
    if (!hasInitialized.current && fields.length === 0) {
      addNewSection();
      hasInitialized.current = true;
    }
  }, [fields, append]);

  useEffect(() => {
    const watchWorkExperiences = watch('workExperiences');
    if (watchWorkExperiences.length) {
      setShowError(false);
    }
  }, [watch('workExperiences'), showError]);

  return (
    <>
      <Container className='rounded-t-none border-t-0 p-4 w-[95%] mx-auto'>
        <div className='mt-10 md:mt-20'>
          <div className='flex justify-end'>
            {hasAnyValue(user?.workExperiences) ? (
              <Button
                variant='link'
                className={cn('w-auto h-auto p-1 text-base', {
                  'text-tomatoRed': isEdit,
                })}
                onClick={() => setIsEdit(!isEdit)}
              >
                {isEdit ? strings.common.cancel : strings.common.edit}
              </Button>
            ) : null}
          </div>
          <div className='flex justify-between'>
            <Typography variant='heading' className='md:text-2xl'>
              {staticText.workExperience}
            </Typography>
            {!hasAnyValue(user?.workExperiences) || isEdit ? (
              <div onClick={addNewSection}>
                <Typography className='text-primary font-semibold cursor-pointer flex items-center gap-1'>
                  <FaPlus />
                  {staticText.buttons.addNew}
                </Typography>
              </div>
            ) : null}
          </div>
          {fields.map((field, sectionIndex) => (
            <div
              key={field.id}
              id={`section-${sectionIndex}`}
              ref={newSectionRef}
            >
              {sectionIndex !== 0 ? (
                <>
                  <Separator className='mt-5 mb-2' />
                  {!hasAnyValue(user?.workExperiences) || isEdit ? (
                    <div className='flex justify-end'>
                      <div onClick={() => deleteSection(sectionIndex)}>
                        <Typography className='text-tomatoRed mb-1 font-semibold cursor-pointer flex items-center gap-1'>
                          <HiOutlineTrash />
                          {staticText.buttons.delete}
                        </Typography>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
              <div className='md:grid md:grid-cols-3 gap-x-4 mt-4'>
                {WORK_EXPERIENCE_FIELDS.map((fieldConfig, index) => (
                  <div key={`${fieldConfig.name}_${index}`}>
                    <Controller
                      control={control}
                      rules={{
                        required:
                          sectionIndex === 0
                            ? `*${fieldConfig.title} is required.`
                            : false,
                      }}
                      name={
                        `workExperiences.${sectionIndex}.${fieldConfig.name}` as keyof IWorkExperiences
                      }
                      render={({ field: { onChange, value, name } }) => {
                        switch (true) {
                          case fieldConfig.isDate:
                            return (
                              <div>
                                <Typography className='flex capitalize font-bold md:text-base mb-3 mt-2'>
                                  {fieldConfig.title}
                                </Typography>
                                <DatePickerField
                                  value={value as unknown as Date}
                                  setValue={onChange}
                                  contanierClassName='w-full rounded-md h-12'
                                  name={name}
                                  errors={errors}
                                  disabled={
                                    !isEdit &&
                                    hasAnyValue(user?.workExperiences)
                                  }
                                />
                              </div>
                            );

                          default:
                            return (
                              <FormField
                                title={fieldConfig.title}
                                placeholder={common.typeHere}
                                name={name}
                                value={value as unknown as string | number}
                                onChange={onChange}
                                errors={errors}
                                labelClassName='font-bold md:text-base mb-3'
                                type={fieldConfig.isNumber ? 'number' : 'text'}
                                disabled={
                                  !isEdit && hasAnyValue(user?.workExperiences)
                                }
                              />
                            );
                        }
                      }}
                    />
                    {showError &&
                      fieldConfig.name === 'nameOfCompany' &&
                      isSubmitted && (
                        <p className='text-redColor text-xs'>
                          {staticText.requiredError}
                        </p>
                      )}
                  </div>
                ))}
                <div className='col-span-3'>
                  <Typography className='flex capitalize font-bold md:text-base mb-3'>
                    {staticText.reasonForLeaving}
                  </Typography>
                  <Controller
                    control={control}
                    name={
                      `workExperiences.${sectionIndex}.reasonForLeaving` as const
                    }
                    render={({ field: { onChange, value } }) => (
                      <Textarea
                        rows={4}
                        value={value as string}
                        onChange={onChange}
                        errors={errors}
                        placeholder={common.typeHere}
                        disabled={!isEdit && hasAnyValue(user?.workExperiences)}
                        className='border-greyWhite rounded-xl resize-none w-full placeholder:text-quickSilver placeholder:text-sm text-sm md:text-sm font-medium min-h-10 text-primaryBlack max-h-40 mt-1 p-2'
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
      <div className='w-[95%] mx-auto py-3 flex justify-between'>
        <div onClick={() => handleSkip(4)}>
          <Typography className='text-primary underline font-semibold cursor-pointer'>
            {staticText.buttons.skip}
          </Typography>
        </div>
        <div className='flex gap-3'>
          <Button
            variant='outline'
            className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
            onClick={handleBack}
            disabled={workExperienceMutation.status === 'pending'}
          >
            {staticText.buttons.back}
          </Button>
          <Button
            className='sm:w-44 md:w-32'
            onClick={handleSubmit(onSubmit)}
            disabled={workExperienceMutation.status === 'pending'}
            loading={workExperienceMutation.status === 'pending'}
          >
            {isEdit ? staticText.buttons.update : staticText.buttons.next}
          </Button>
        </div>
      </div>
    </>
  );
};

export default WorkExperience;
