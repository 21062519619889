import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { FaPlus } from 'react-icons/fa6';
import { HiOutlineTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { IUser } from '@/@types/auth';
import { IFinalStep, IFinalStepPayload } from '@/@types/profile';
import Container from '@/components/Container';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/seperator';
import { Textarea } from '@/components/ui/textarea';
import {
  EMERGENCY_CONTACT_FIELDS,
  REFERENCES_FIELDS,
  WORKING_RELATIVES_FIELDS,
} from '@/constants/profile';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useFormContext } from '@/provider/FormProvider/index';
import { ROUTES } from '@/routes';
import { profileOthersDataStep } from '@/services/profile';
import { hasAnyValue } from '@/utils/common';

const FinalStep = () => {
  const { profile: staticText, common } = strings;
  const { user, setUser } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();

  const { stepSix, handleBack, handleNext, setData } = useFormContext();

  const newRelativeRef = useRef<HTMLDivElement | null>(null);
  const hasInitialized = useRef(false);

  const newEmergencyContactRef = useRef<HTMLDivElement | null>(null);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<IFinalStep>({
    mode: 'all',
    defaultValues: user?.others || stepSix || {},
  });

  const { fields, append, remove } = useFieldArray<
    IFinalStep,
    'relation',
    'id'
  >({
    control,
    name: 'relation',
  });

  const {
    fields: emergencyFields,
    append: appendEmergency,
    remove: removeEmergency,
  } = useFieldArray<IFinalStep, 'emergency', 'id'>({
    control,
    name: 'emergency',
  });

  const {
    fields: referenceFields,
    append: appendReference,
    remove: removeReferenceField,
  } = useFieldArray<IFinalStep, 'chararacterReferences', 'id'>({
    control,
    name: 'chararacterReferences',
  });
  const addReference = () => {
    appendReference({
      name: '',
      relationship: '',
      address: '',
      contactNo: '',
    });
  };

  const removeReference = (index: number) => {
    removeReferenceField(index);
  };

  const addNewRelative = () => {
    append({
      name: '',
      relationship: '',
    });
    setTimeout(() => {
      if (newRelativeRef.current) {
        newRelativeRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 100);
  };

  const removeRelative = (index: number) => {
    remove(index);
  };

  const addEmergencyContact = () => {
    appendEmergency({
      name: '',
      relationship: '',
      address: '',
      telNo: '',
    });
    setTimeout(() => {
      if (newEmergencyContactRef.current) {
        newEmergencyContactRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 100);
  };

  const removeEmergencyContact = (index: number) => {
    removeEmergency(index);
  };

  const postProfileOthersMutation = useGenericMutation<
    IFinalStepPayload,
    IUser | boolean
  >(profileOthersDataStep, {
    onSuccess: (result) => {
      if (typeof result !== 'boolean') {
        setData({ step: 6, data: result?.others as IFinalStep });
        setUser({ ...user, others: result?.others } as IUser);
        handleNext();
      }
    },
  });

  const onSubmit: SubmitHandler<IFinalStep> = (data) => {
    const paypload = {
      chargedCourt: data?.chargedCourt?.trim(),
      relation: JSON.stringify(data.relation),
      emergency: JSON.stringify(data.emergency),
      chararacterReferences: JSON.stringify(data.chararacterReferences),
    };
    if (isDirty) {
      postProfileOthersMutation.mutate(paypload as IFinalStepPayload);
      navigate(ROUTES.DASHBOARD);
    } else {
      handleNext();
      setData({ step: 6, data: data });
    }
  };

  useEffect(() => {
    if (!hasInitialized.current && fields.length === 0) {
      addNewRelative();
      addEmergencyContact();
      addReference();
      hasInitialized.current = true;
    }
  }, [
    fields,
    append,
    emergencyFields,
    appendEmergency,
    referenceFields,
    appendReference,
  ]);

  const filteredOthers = useMemo(() => {
    return Object.fromEntries(
      Object.entries(user?.others || {}).filter(([key]) => {
        return key !== 'documents';
      }),
    );
  }, [user?.others]);

  return (
    <>
      <Container className='rounded-t-none border-t-0 p-4 w-[95%] mx-auto'>
        <div className='mt-10 md:mt-20'>
          {hasAnyValue(filteredOthers) ? (
            <div className='flex justify-end'>
              <Button
                variant='link'
                className={cn('w-auto h-auto p-1 text-base', {
                  'text-tomatoRed': isEdit,
                })}
                onClick={() => setIsEdit(!isEdit)}
              >
                {isEdit ? strings.common.cancel : strings.common.edit}
              </Button>
            </div>
          ) : null}
          <div className='md:flex justify-between'>
            <Typography
              variant='heading'
              className='text-lg md:text-2xl capitalize'
            >
              {staticText.relativeWorkingWithSmoothMovesQuestion}
            </Typography>
            {!hasAnyValue(filteredOthers) || isEdit ? (
              <div
                onClick={addNewRelative}
                className='float-right w-44 flex justify-end md:float-none cursor-pointer'
              >
                <Typography className='text-primary font-semibold flex items-center gap-1'>
                  <FaPlus />
                  {staticText.buttons.addNew}
                </Typography>
              </div>
            ) : null}
          </div>
          {fields.length ? (
            fields.map((field, sectionIndex) => (
              <div
                key={field.id}
                id={`section-${sectionIndex}`}
                ref={newRelativeRef}
              >
                {sectionIndex !== 0 ? (
                  <>
                    <Separator className='mt-5 mb-2' />
                    <div className='flex justify-end'>
                      <div onClick={() => removeRelative(sectionIndex)}>
                        <Typography className='text-tomatoRed mb-1 font-semibold cursor-pointer flex items-center gap-1'>
                          <HiOutlineTrash />
                          {staticText.buttons.delete}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className='md:grid md:grid-cols-3 gap-x-4 mt-4'>
                  {WORKING_RELATIVES_FIELDS.map((fieldConfig, index) => (
                    <div key={`${fieldConfig.name}_${index}`}>
                      <Controller
                        control={control}
                        name={
                          `relation.${sectionIndex}.${fieldConfig.name}` as keyof IFinalStep
                        }
                        render={({ field: { onChange, value, name } }) => (
                          <FormField
                            title={fieldConfig.title}
                            placeholder={common.typeHere}
                            name={name}
                            value={value as unknown as string | number}
                            onChange={onChange}
                            errors={errors}
                            labelClassName='font-bold md:text-base mb-3'
                            disabled={!isEdit && hasAnyValue(filteredOthers)}
                          />
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <Typography>{common.noDataAvailable}</Typography>
          )}
          <div className='my-6'>
            <Typography
              variant='heading'
              className='text-lg md:text-2xl capitalize'
            >
              {staticText.chargedInCourt}
            </Typography>
          </div>
          <Typography className='flex capitalize font-bold md:text-base mb-3'>
            {staticText.name}
          </Typography>
          <Controller
            control={control}
            name='chargedCourt'
            render={({ field: { onChange, value } }) => (
              <Textarea
                rows={4}
                value={value as string}
                onChange={onChange}
                errors={errors}
                placeholder={common.typeHere}
                disabled={!isEdit && hasAnyValue(filteredOthers)}
                className='border-greyWhite rounded-xl resize-none w-full placeholder:text-quickSilver placeholder:text-sm text-sm md:text-sm font-medium min-h-10 text-primaryBlack max-h-40 mt-1 p-2'
              />
            )}
          />
          <div className='my-6 md:flex justify-between'>
            <Typography
              variant='heading'
              className='text-lg md:text-2xl capitalize'
            >
              {staticText.emergencyContact}
            </Typography>
            {!hasAnyValue(filteredOthers) || isEdit ? (
              <div onClick={addEmergencyContact} className='flex justify-end'>
                <Typography className='text-primary font-semibold cursor-pointer flex items-center gap-1'>
                  <FaPlus />
                  {staticText.buttons.addNew}
                </Typography>
              </div>
            ) : null}
          </div>
          {emergencyFields.length ? (
            emergencyFields.map((field, sectionIndex) => (
              <div
                key={field.id}
                id={`section-${sectionIndex}`}
                ref={newEmergencyContactRef}
              >
                {sectionIndex !== 0 ? (
                  <>
                    <Separator className='mt-5 mb-2' />
                    <div className='flex justify-end'>
                      <div onClick={() => removeEmergencyContact(sectionIndex)}>
                        <Typography className='text-tomatoRed mb-1 font-semibold cursor-pointer flex items-center gap-1'>
                          <HiOutlineTrash />
                          {staticText.buttons.delete}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className='md:grid md:grid-cols-3 gap-x-4 mt-4'>
                  {EMERGENCY_CONTACT_FIELDS.map((fieldConfig, index) => (
                    <div key={`${fieldConfig.name}_${index}`}>
                      <Controller
                        control={control}
                        name={
                          `emergency.${sectionIndex}.${fieldConfig.name}` as keyof IFinalStep
                        }
                        render={({ field: { onChange, value, name } }) => (
                          <FormField
                            title={fieldConfig.title}
                            placeholder={common.typeHere}
                            name={name}
                            value={value as unknown as string | number}
                            onChange={onChange}
                            errors={errors}
                            labelClassName='font-bold md:text-base mb-3'
                            type={fieldConfig.isNumber ? 'number' : 'text'}
                            disabled={!isEdit && hasAnyValue(filteredOthers)}
                          />
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <Typography>{common.noDataAvailable}</Typography>
          )}
          <div className='py-6 flex justify-between'>
            <Typography
              variant='heading'
              className='text-lg md:text-2xl capitalize'
            >
              {staticText.reference}
            </Typography>
            {!hasAnyValue(filteredOthers) || isEdit ? (
              <div
                onClick={addReference}
                className='cursor-pointer flex items-center gap-1'
              >
                <FaPlus />
                <Typography className='text-primary font-semibold'>
                  {staticText.buttons.addNew}
                </Typography>
              </div>
            ) : null}
          </div>
          {referenceFields.length ? (
            referenceFields.map((field, sectionIndex) => (
              <div key={field.id} id={`chararacterReference-${sectionIndex}`}>
                {sectionIndex !== 0 ? (
                  <>
                    <Separator className='mt-5 mb-2' />
                    <div className='flex justify-end mt-2'>
                      <div
                        onClick={() => removeReference(sectionIndex)}
                        className='cursor-pointer'
                      >
                        <Typography className='text-tomatoRed font-semibold flex items-center gap-1'>
                          <HiOutlineTrash />
                          {staticText.buttons.delete}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className='md:grid md:grid-cols-3 gap-x-4 mt-4'>
                  {REFERENCES_FIELDS.map((fieldConfig, index) => (
                    <div key={`${fieldConfig.name}_${index}`}>
                      <Controller
                        control={control}
                        name={
                          `chararacterReferences.${sectionIndex}.${fieldConfig.name}` as keyof IFinalStep
                        }
                        render={({ field: { onChange, value, name } }) => (
                          <FormField
                            title={fieldConfig.title}
                            placeholder={common.typeHere}
                            name={name}
                            value={value as unknown as string | number}
                            onChange={onChange}
                            errors={errors}
                            labelClassName='font-bold md:text-base mb-3'
                            type={fieldConfig.isNumber ? 'number' : 'text'}
                            disabled={!isEdit && hasAnyValue(filteredOthers)}
                          />
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <Typography>{common.noDataAvailable}</Typography>
          )}
        </div>
      </Container>
      <div className='w-[95%] mx-auto py-3 flex justify-between'>
        <div onClick={() => navigate(ROUTES.DASHBOARD)}>
          <Typography className='text-primary underline font-semibold cursor-pointer'>
            {staticText.buttons.skip}
          </Typography>
        </div>
        <div className='flex gap-3'>
          <Button
            variant='outline'
            className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
            onClick={handleBack}
            disabled={postProfileOthersMutation.status === 'pending'}
          >
            {staticText.buttons.back}
          </Button>
          <Button
            className='sm:w-44 md:w-32'
            onClick={handleSubmit(onSubmit)}
            disabled={postProfileOthersMutation.status === 'pending'}
            loading={postProfileOthersMutation.status === 'pending'}
          >
            {isEdit ? staticText.buttons.update : staticText.buttons.submit}
          </Button>
        </div>
      </div>
    </>
  );
};

export default FinalStep;
