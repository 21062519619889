import { FaRegUser } from 'react-icons/fa';
import { IoDocumentOutline } from 'react-icons/io5';

import LogoutIcon from '@/assets/svgs/LogoutIcon';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';

export const PASSWORD_VALIDATIONS_POINTS = [
  strings.passwordValidationText.charLength,
  strings.passwordValidationText.lowerCase,
  strings.passwordValidationText.upperCase,
  strings.passwordValidationText.number,
  strings.passwordValidationText.specialChar,
];

export const enum USER_PROVIDER {
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE',
}
export const COMMON_ERROR = 'Something went wrong!';
export const COMMON_SUCCESS = 'Operation completed successfully!';

export enum USER_TYPE {
  CANDIDATE = 'CANDIDATE',
  RECRUITER = 'RECRUITER',
  COMPANY_CLIENT = 'COMPANY_CLIENT',
  ADMIN = 'ADMIN',
}

export const INPUT_ERRORS = {
  EMAIL: {
    required: '*Email is required.',
    pattern: '*Enter a valid email.',
  },
  PASSWORD: {
    required: strings.passwordValidationText.required,
    minLength: strings.passwordValidationText.charLength,
    lowercase: strings.passwordValidationText.lowerCase,
    uppercase: strings.passwordValidationText.upperCase,
    number: strings.passwordValidationText.number,
    specialChar: strings.passwordValidationText.specialChar,
  },
  CONFIRM_PASSWORD: {
    required: '*Confirm Password is required.',
    match: '*Passwords do not match',
  },
  NAME: {
    required: '*Name is required.',
  },
  FIRST_NAME: {
    required: '*First name is required.',
  },
  LAST_NAME: {
    required: '*Last name is required.',
  },
  PHONE_NUMBER: {
    required: '*Phone Number is required.',
  },
  PRESENT_ADDRESS: {
    required: '*Present Address is required.',
  },
  FILE: {
    required: '*File is required.',
  },
  RANGE: {
    required: '*Both Min and Max values are required',
    validate: '*Min value must be smaller than Max value',
    numberField: '*Please enter a valid number',
  },
  POSITION_DESIRED: {
    required: '*Position desired is required.',
  },
  EXPECTED_SALARY: {
    required: '*Expected Salary is required.',
  },
  CONTACT_NO: {
    required: '*Contact number is required.',
  },
  PERMANENT_ADDRESS: {
    required: '*Permanent address is required.',
  },
  SEX: {
    required: '*Sex is required.',
  },
  BIRTH_PLACE: {
    required: '*Birthplace is required.',
  },
  BIRTH_DATE: {
    futureDate: '*Birthdate cannot be in the future.',
    required: '*Birthdate is required.',
  },
  AGE: {
    required: '*Age is required.',
  },
  NATIONALITY: {
    required: '*Nationality is required.',
  },
  RELIGION: {
    required: '*Religion is required.',
  },
  SSS_NO: {
    required: '*SSS number is required.',
  },
  TIN: {
    required: '*TIN is required.',
  },
  PHIL_HEALTH_NO: {
    required: '*PhilHealth number is required.',
  },
  HDMF_NO: {
    required: '*HDMF number is required.',
  },
  COUNTRY: {
    required: '*Country is required.',
  },
  ZIP_CODE: {
    required: '*ZIP code is required.',
  },
  CITY: {
    required: '*City is required.',
  },
  SOURCE_NAME: {
    required: '*Source Name is required.',
  },
  SOURCE_TYPE: {
    required: '*Source Type is required.',
  },
  SKILLS: {
    required: '*Special Skills is required.',
  },
  DEPARTMENT: {
    required: '*Department is required.',
  },
  HEIGHT_AND_WEIGHT: {
    validate: '*Value must be less than or equal to 200.',
  },
  RESUME: {
    required: '*Resume is required.',
  },
};

export enum LOCAL_CONSTANT {
  USER_TOKEN = 'token',
  USER = 'user',
}
export enum HEADER_DROPDOWN_OPTIONS {
  PROFILE = 'PROFILE',
  MY_DOCUMENTS = 'MY_DOCUMENTS',
  LOGOUT = 'LOGOUT',
}

export const PROFILE_DROPDOWN_OPTIONS = [
  {
    value: HEADER_DROPDOWN_OPTIONS.PROFILE,
    label: strings.sidebarRoutes.profile,
    icon: <FaRegUser />,
  },
  {
    value: HEADER_DROPDOWN_OPTIONS.MY_DOCUMENTS,
    label: strings.sidebarRoutes.myDocuments,
    icon: <IoDocumentOutline className='text-xl' />,
  },
  {
    value: HEADER_DROPDOWN_OPTIONS.LOGOUT,
    label: strings.sidebarRoutes.logout,
    icon: <LogoutIcon />,
  },
];
export const SIDEBAR_OPTIONS = [
  {
    label: strings.sidebarRoutes.profile,
    location: ROUTES.PROFILE,
    icon: <FaRegUser />,
  },
  {
    label: strings.sidebarRoutes.myDocuments,
    location: ROUTES.MY_DOCUMENTS,
    icon: <IoDocumentOutline className='text-xl' />,
  },
];
export const DONUT_COLORS = [
  '#528328',
  '#3A6188',
  '#379494',
  '#EDB839',
  '#BA4D44',
  '#4340A3',
  '#49739D',
  '#DF495C',
  '#C65D54',
];

export enum TwoFactorAuthType {
  Disable = 'Disable',
  Enable = 'Enable',
  Login2FA = 'Login2FA',
  VerifyEmail = 'VerifyEmail',
  ResetPassword = 'ResetPassword',
}

export const enum CONFIRMATION_MODAL {
  DELETE = 'Delete',
  REJECT = 'Reject',
}

export const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB
